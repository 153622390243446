<h1>My Students</h1>
<p class="p-text-secondary mb-3">
  View a list of students across all of your Fundations classes.
</p>

<dx-data-grid
  [dataSource]="store"
  [remoteOperations]="true"
  [rowAlternationEnabled]="true"
  [showBorders]="true"
  noDataText="No students available to display."
  data-automationId="my_students_dashboard"
>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-sorting mode="single"></dxo-sorting>
  <dxo-paging [pageSize]="25"></dxo-paging>
  <dxo-pager
    [allowedPageSizes]="[25, 50, 75, 100]"
    [showInfo]="true"
    [showPageSizeSelector]="true"
    displayMode="full"
  ></dxo-pager>
  <dxo-state-storing
    [enabled]="true"
    storageKey="dx_storage_student_roster"
    type="localStorage"
  ></dxo-state-storing>
  <dxi-column
    caption="Name"
    cellTemplate="rosterLinkTemplate"
    dataField="name"
    dataType="string"
    sortOrder="asc"
  ></dxi-column>
  <dxi-column
    caption="Class"
    cellTemplate="classLinkTemplate"
    dataField="class"
    dataType="string"
    selectedFilterOperation="startsWith"
  ></dxi-column>
  <dxi-column alignment="center" caption="Student Progress">
    <dxi-column
      alignment="left"
      caption="Most Recent Unit Test"
      cellTemplate="unitTemplate"
      dataField="lastTestId"
      dataType="number"
    >
      <dxo-lookup
        [dataSource]="unitsFilter"
        displayExpr="name"
        valueExpr="value"
      ></dxo-lookup>
    </dxi-column>
    <dxi-column
      alignment="center"
      caption="Most Recent Test Score"
      cellTemplate="scoreTemplate"
      dataField="lastScore"
      dataType="number"
    ></dxi-column>
  </dxi-column>

  <div *dxTemplate="let row of 'rosterLinkTemplate'">
    <a
      [routerLink]="['/reports/student', row.data.classId, row.data.id]"
      data-automationId="student_name_link"
      title="View {{ row.data.name }}'s report"
      class="default-link"
      >{{ row.data.name }}</a
    >
  </div>

  <div *dxTemplate="let row of 'classLinkTemplate'">
    <a
      [routerLink]="['/utt/class', row.data.classId]"
      title="View {{ row.data.class }} class overview"
      class="default-link"
      >{{ row.data.class }}</a
    >
  </div>

  <div *dxTemplate="let row of 'unitTemplate'">
    <span>
      {{ row.data.lastTestName }}
    </span>
  </div>

  <div *dxTemplate="let row of 'scoreTemplate'">
    <a
      *ngIf="row.data.lastScore > -1"
      [routerLink]="[
        '/utt/class',
        row.data.classId,
        'student',
        row.data.id,
        'review',
        row.data.lastAttemptId
      ]"
      class="no-underline"
    >
      <p-tag
        [styleClass]="
          row.data.lastScore >= benchmarkScores.onTrack.min
            ? 'score-tag tag-success'
            : row.data.lastScore >= benchmarkScores.failing.max
            ? 'score-tag tag-warning'
            : 'score-tag tag-danger'
        "
        [value]="row.data.lastScore"
      ></p-tag>
    </a>
    <span *ngIf="row.data.lastScore === -1">No recent score</span>
  </div>
</dx-data-grid>
