import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { Wilson } from '../../def/Wilson';
import WilsonProgram = Wilson.WilsonProgram;

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  /*istanbul ignore next*/
  trackLevelChange(programId: number) {
    switch (programId) {
      case WilsonProgram.FundationsCommunityLevelK: {
        mixpanel.track('Fundations Report Selected', {
          level: 'Fundations Level K',
        });
        break;
      }
      case WilsonProgram.FundationsCommunityLevel1: {
        mixpanel.track('Fundations Report Selected', {
          level: 'Fundations Level 1',
        });
        break;
      }
      case WilsonProgram.FundationsCommunityLevel2: {
        mixpanel.track('Fundations Report Selected', {
          level: 'Fundations Level 2',
        });
        break;
      }
      case WilsonProgram.FundationsCommunityLevel3: {
        mixpanel.track('Fundations Report Selected', {
          level: 'Fundations Level 3',
        });
        break;
      }
      default: {
        return;
      }
    }
  }
}
