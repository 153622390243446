import { Injectable } from '@angular/core';
import { ApiService } from '@wilson/wilsonng';
import { Observable } from 'rxjs';
import { Wilson } from 'src/def/Wilson';
import GetSchoolReportResponse = Wilson.GetSchoolReportResponse;
import GetSchoolReportByUnit = Wilson.GetSchoolReportByUnit;
import GetSchoolTeachersByUnit = Wilson.GetSchoolTeachersByUnit;
import TeacherReportByUnit = Wilson.TeacherReportByUnit;
import School = Wilson.School;
import Teacher = Wilson.Teacher;

@Injectable({
  providedIn: 'root',
})
export class SchoolReportsService {
  constructor(private apiService: ApiService) {}

  getSchools = (): Observable<School[]> =>
    this.apiService.get<School[]>('schoolReports/GetSchools/');

  getSchoolReport(
    schoolId: string,
    program: number
  ): Observable<GetSchoolReportResponse> {
    return this.apiService.get<GetSchoolReportResponse>(
      `schoolReports/GetSchoolReport/${schoolId}/${program}`
    );
  }

  getTeachersByUnit(body: GetSchoolTeachersByUnit): Observable<Teacher[]> {
    return this.apiService.post<Teacher[]>(
      `schoolReports/GetTeachersByUnit/`,
      body
    );
  }

  getTeacherReportByUnit(
    getSchoolReport: GetSchoolReportByUnit
  ): Observable<TeacherReportByUnit> {
    return this.apiService.post<TeacherReportByUnit>(
      `schoolReports/GetTeacherReportByUnit/`,
      getSchoolReport
    );
  }
}
