import { Injectable } from '@angular/core';
import { ApiService } from '@wilson/wilsonng';
import { Observable } from 'rxjs';
import { Wilson } from 'src/def/Wilson';
import GetUnitTestResponse = Wilson.GetUnitTestResponse;
import GenerateUnitTestResponse = Wilson.GenerateUnitTestResponse;
import EditUnitTestResponse = Wilson.EditUnitTestResponse;

@Injectable({
  providedIn: 'root',
})
export class TestService {
  private controller = 'test';

  constructor(private apiService: ApiService) {}

  getNewTestResponse(
    studentId: string,
    testDefinitionId: string
  ): Observable<GetUnitTestResponse> {
    return this.apiService.get<GetUnitTestResponse>(
      `${this.controller}/GetNewTestResponse/student/${studentId}/definition/${testDefinitionId}`
    );
  }

  getEditTestResponse(
    studentId: string,
    testResponseId: string
  ): Observable<GetUnitTestResponse> {
    return this.apiService.get<GetUnitTestResponse>(
      `${this.controller}/GetEditTestResponse/student/${studentId}/response/${testResponseId}`
    );
  }

  generateTestResponse(
    generateUnitTestResponse: GenerateUnitTestResponse
  ): Observable<void> {
    return this.apiService.post<void>(
      `${this.controller}/GenerateTestResponse/`,
      generateUnitTestResponse
    );
  }

  editTestResponse(
    editUnitTestResponse: EditUnitTestResponse
  ): Observable<void> {
    return this.apiService.put<void>(
      `${this.controller}/EditTestResponse/`,
      editUnitTestResponse
    );
  }
}
