import { Injectable } from '@angular/core';
import { ApiService } from '@wilson/wilsonng';
import { Observable } from 'rxjs';
import { Wilson } from 'src/def/Wilson';
import GenerateNewClass = Wilson.GenerateNewClass;
import BaseResult = Wilson.BaseResult;

@Injectable({
  providedIn: 'root',
})
export class NewClassService {
  constructor(private apiService: ApiService) {
    /* istanbul ignore next */
    if (window['parent']['Cypress']) window['ApiService'] = this;
  }
  generateNewClass(newClass: GenerateNewClass): Observable<BaseResult> {
    return this.apiService.put<BaseResult>(
      'createClass/CreateClass/',
      newClass
    );
  }
}
