<p-dialog
  [visible]="true"
  header="Add Existing Student"
  [modal]="true"
  [style]="{ width: '30rem', minHeight: '30rem' }"
  styleClass="mx-3"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
  (onHide)="onCancel()"
>
  <p-autoComplete
    (completeMethod)="searchStudents($event)"
    (onSelect)="onSelectStudent($event)"
    [autoHighlight]="true"
    [autofocus]="true"
    [emptyMessage]="studentEmptyMessage"
    [forceSelection]="true"
    [suggestions]="suggestedStudents"
    [style]="{ width: '100%' }"
    [inputStyle]="{ width: '100%' }"
    data-automationId="student_search"
    field="student"
    inputId="studentSearch"
    placeholder="Add existing student..."
    showClear="true"
    showEmptyMessage="true"
    maxlength="75"
  >
    <ng-template let-student pTemplate="student">
      <p>{{ student.name }}</p>
    </ng-template>
  </p-autoComplete>
  <ng-template pTemplate="footer">
    <button pButton class="p-button-danger" (click)="onCancel()">Cancel</button>
  </ng-template>
</p-dialog>
