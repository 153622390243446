<h1>Create Class</h1>
<p class="p-text-secondary">
  Enter a class name, level, school year, and class roster on this page.
</p>

<div class="card card-cluster fundations-{{ classIconLevel | lowercase }}">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-sm col-md-4">
        <div class="flex align-items-center card mb-0">
          <p-avatar
            [label]="classIconLevel"
            shape="circle"
            size="xlarge"
            styleClass="m-3 program-icon"
          ></p-avatar>

          <div class="field w-100">
            <label class="block" for="className">Class Name</label>
            <input
              class="p-inputtext-lg align-self-stretch text-center w-100"
              data-automationId="nc_form_field_class_name"
              formControlName="name"
              id="className"
              pInputText
              placeholder="Enter name..."
              type="text"
              maxlength="75"
            />
            <small
              *ngIf="
                form.controls['name'].dirty && form.controls['name'].invalid
              "
              class="text-danger"
              ><em
                >This required field can only contain alphanumeric
                characters.</em
              ></small
            >
          </div>

          <div class="row w-100">
            <div class="field col-6 py-0 ps-0 pe-1">
              <label class="block" for="classLevel">Level</label>
              <p-dropdown
                (onChange)="updateClassIcon()"
                [options]="levels"
                class="w-100"
                data-automationId="nc_form_field_level"
                formControlName="level"
                id="classLevel"
                optionLabel="label"
                optionValue="value"
                placeholder="Select Level"
                styleClass="w-100"
              ></p-dropdown>
            </div>
            <div class="field col-6 py-0 pe-0 ps-1">
              <label class="block" for="classYear">School Year</label>
              <p-dropdown
                (onChange)="trackSchoolYearChange()"
                [options]="years"
                class="w-100"
                data-automationId="nc_form_field_year"
                formControlName="year"
                id="classYear"
                optionLabel="label"
                optionValue="value"
                placeholder="Select Year"
                styleClass="w-100"
              ></p-dropdown>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 py-2 d-block d-md-none"></div>

      <div class="col-sm col-md-8">
        <div class="card mb-0 h-100">
          <dx-data-grid #dataGrid [dataSource]="roster">
            <dxo-editing
              [allowAdding]="true"
              [allowDeleting]="true"
              [allowUpdating]="true"
              [confirmDelete]="false"
              [selectTextOnEditStart]="true"
              mode="cell"
              startEditAction="click"
            >
            </dxo-editing>
            <dxo-toolbar>
              <dxi-item location="before">
                <h3>Students</h3>
              </dxi-item>
              <dxi-item location="after">
                <div *dxTemplate>
                  <p-splitButton
                    label="Add New Student"
                    icon="pi pi-user"
                    (onClick)="addStudentRow()"
                    [model]="addNewStudentOptions"
                    [menuStyle]="{ width: 'auto' }"
                    styleClass="addStudentButton p-button-outlined mr-2"
                    data-automationId="add_new_student_button"
                  ></p-splitButton>
                  <i
                    class="pi pi-info-circle"
                    (click)="toggleCsvInfoOverlay($event)"
                  ></i>
                  <p-overlayPanel #csvInfoOverlay>
                    <ng-template pTemplate="content">
                      <p style="max-width: 300px; padding: 20px">
                        <b>Uploading a CSV file</b>: The first row of your CSV
                        file should include the header 'Name'. Add each
                        student's name in a separate row under this header.
                        <a
                          href="./assets/csv/New_Class_Upload_Example.csv"
                          download="New_Class_Upload_Example.csv"
                          >See sample</a
                        >.
                      </p>
                    </ng-template>
                  </p-overlayPanel>
                </div>
              </dxi-item>
              <dxi-item location="after" name="exportButton"></dxi-item>
            </dxo-toolbar>
            <dxi-column
              caption="First Name"
              dataField="firstName"
              [editorOptions]="{ maxLength: 75 }"
            >
              <dxi-validation-rule
                [min]="1"
                message="Student name cannot be blank"
                type="required"
              ></dxi-validation-rule>
            </dxi-column>
            <dxi-column
              caption="Last Name"
              dataField="lastName"
              [editorOptions]="{ maxLength: 75 }"
            >
              <dxi-validation-rule
                [min]="1"
                message="Student name cannot be blank"
                type="required"
              ></dxi-validation-rule>
            </dxi-column>

            <div
              *dxTemplate="let data of 'studentAvatarTemplate'"
              class="d-flex align-items-center justify-content-center"
            >
              <app-avatar-student
                [initials]="data.value"
                size="normal"
              ></app-avatar-student>
            </div>
          </dx-data-grid>
        </div>
      </div>
    </div>

    <button
      (click)="prepareGenerateClass()"
      [disabled]="!form.valid"
      class="btn btn-primary mt-2 align-self-end px-5 text-white"
      data-automationId="nc_form_submit_button"
      label="Create"
      pButton
      type="submit"
    ></button>
  </form>
  <input
    #csvSelector
    (change)="uploadCsv($event.target['files'][0])"
    accept=".csv"
    style="display: none"
    type="file"
  />
</div>
<app-search-students
  *ngIf="addingExistingStudent"
  (selectStudent)="selectStudent($event)"
  [existingStudentIds]="existingStudentIds"
  (cancelAddExisting)="cancelAddExistingStudent()"
>
</app-search-students>
