import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import ArrayStore from 'devextreme/data/array_store';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NewClassService } from 'src/app/services/new-class.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { InitialLoadService } from 'src/app/services/initial-load.service';
import { SpinnerService } from '@wilson/wilsonng';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import mixpanel from 'mixpanel-browser';
import { Wilson } from 'src/def/Wilson';
import GenerateNewClass = Wilson.GenerateNewClass;
import GenerateStudent = Wilson.GenerateStudent;
import Student = Wilson.Student;
import BaseResult = Wilson.BaseResult;
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  templateUrl: './new-class.component.html',
  styleUrls: ['./new-class.component.scss'],
})
export class NewClassComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;

  @ViewChild('csvSelector', { static: false })
  csvSelector: ElementRef;

  @ViewChild('csvInfoOverlay') csvInfoOverlay!: OverlayPanel;

  form: FormGroup<{
    name: FormControl<string>;
    level: FormControl<string>;
    year: FormControl<number>;
  }>;

  classIconLevel = '?';

  selectedStudent: Student;
  existingStudentIds: string[] = [];

  levels = [
    {
      label: 'Level K',
      value: 'fundations-k',
    },
    {
      label: 'Level 1',
      value: 'fundations-1',
    },
    {
      label: 'Level 2',
      value: 'fundations-2',
    },
    {
      label: 'Level 3',
      value: 'fundations-3',
    },
  ];

  addingExistingStudent: boolean;
  addNewStudentOptions: MenuItem[] = [
    {
      label: 'Upload CSV',
      icon: 'pi pi-upload',
      command: () => this.csvSelector.nativeElement.click(),
    },
  ];

  removeExistingStudentId = (removedId: string) => {
    this.existingStudentIds = this.existingStudentIds.filter(
      (id) => id !== removedId
    );
  };

  roster = new ArrayStore({
    key: 'id',
    onInserting: (values: GenerateStudent) => {
      values['isNew'] = true;
    },
    onInserted: this.onInserted,
    onRemoved: this.removeExistingStudentId,
  });

  years: { label: string; value: number }[];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private newClassService: NewClassService,
    private initialLoadService: InitialLoadService,
    private spinner: SpinnerService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.initForm();
    mixpanel.track('Go to New Class Create');
  }

  initForm(): void {
    this.populateSchoolYears();

    this.form = this.fb.group({
      name: ['', [Validators.required, this.alphanumericValidator()]],
      level: [this.levels[1].value, Validators.required],
      year: [this.years[2].value, Validators.required], // There are 5 years populated. Index 2, the middle, is the current year.
    });

    this.updateClassIcon();
  }

  selectStudent(student: Student): void {
    student['isNew'] = false;
    this.selectedStudent = student;
    this.existingStudentIds = [
      ...this.existingStudentIds,
      this.selectedStudent.id,
    ];
    this.roster.push([
      { type: 'insert', data: this.selectedStudent, index: -1 },
    ]);
    this.selectedStudent = null;
    this.addingExistingStudent = false;
  }

  updateClassIcon(): void {
    this.classIconLevel = this.initialLoadService.initialLoad.funPrograms.find(
      (programDef) => programDef.key === this.form.value.level
    ).initials;

    mixpanel.track('UTTLevelSelect', { UTTLevel: this.form.value.level });
  }

  trackSchoolYearChange(): void {
    mixpanel.track('UTTYearSelect', { UTTSchoolYear: this.form.value.year });
  }

  /*istanbul ignore next*/
  onInserted(): void {
    mixpanel.track('UTTAddStudent');
  }

  populateSchoolYears(): void {
    const currentDate = new Date(
      this.initialLoadService.initialLoad.currentDate
    );

    // A range of 5 years, with the current year in the middle (0), is generated with the array of offsets given.
    this.years = [-2, -1, 0, 1, 2]
      .map(
        (
          offset // Apply the offsets from the array to the current year from the currentDate.
        ) => currentDate.getFullYear() + offset - +(currentDate.getMonth() < 6) // If current month is before July, use previous year.
      )
      .map((year) => ({
        label: `${year}-${year + 1}`,
        value: year,
      }));
  }

  alphanumericValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValid = /^[a-zA-Z0-9\s]*$/.test(control.value);
      return isValid ? null : { alphanumeric: true };
    };
  }

  async prepareGenerateClass(): Promise<void> {
    this.spinner.show();

    await this.dataGrid.instance.saveEditData();
    const allStudents = await this.roster.load();
    const newClass: GenerateNewClass = {
      name: this.form.value.name,
      program: this.initialLoadService.initialLoad.funPrograms.find(
        (programDef) => programDef.key === this.form.value.level
      ).numericId,
      startingYear: this.form.value.year,
      students: allStudents,
    };

    this.generateNewClass(newClass)
      .pipe(
        tap(() => {
          this.spinner.hide();
        })
      )
      .subscribe();
  }

  generateNewClass(newClass: GenerateNewClass): Observable<BaseResult> {
    // filter our all students that have no name
    newClass.students = newClass.students.filter(
      (student) => student.firstName?.length && student.lastName?.length
    );
    return this.newClassService.generateNewClass(newClass).pipe(
      tap((result) => {
        if (result) {
          const key = Object.keys(result)[0];
          this.messageService.add({
            severity: 'error',
            summary: `Invalid Form: ${key}`,
            detail: result[key],
          });
        } else {
          this.router.navigate(['/utt', 'classes']);
          mixpanel.track('New Class Generated');
        }
      })
    );
  }

  // not worth testing, it's just a pass through
  /* istanbul ignore next */
  addStudentRow(): void {
    void this.dataGrid.instance.addRow();
  }

  getStudentInitials(data: Student) {
    return [data.firstName?.substring(0, 1), data.lastName?.substring(0, 1)]
      .join('')
      .toUpperCase();
  }

  // incredibly difficult to test, and not worth it as it will change for v2
  /* istanbul ignore next */
  uploadCsv(file: File): void {
    if (!file) return;
    const reader = new FileReader();

    reader.onloadend = () => {
      const csv = reader.result as string;
      // Skip the header row
      const rowStrings = csv.split('\n').slice(1);
      if (!rowStrings.length) return;
      try {
        rowStrings.forEach((line) => {
          const columns = line.split(',');
          const firstName = columns[0]?.trim();
          const lastName = columns[1]?.trim();
          if (firstName && lastName) {
            const student = { firstName, lastName, isNew: true };
            this.roster.push([{ type: 'insert', data: student, index: 0 }]);
          }
        });
      } catch (error) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `'There was an issue with processing the CSV file:', ${error}`,
        });
        return;
      }
    };

    reader.readAsText(file);
  }

  cancelAddExistingStudent(): void {
    this.addingExistingStudent = false;
  }

  toggleCsvInfoOverlay(event: any) {
    this.csvInfoOverlay.toggle(event);
  }
}
