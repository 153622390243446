import { Component, EventEmitter, Input, Output } from '@angular/core';
import { lastValueFrom, tap } from 'rxjs';
import { StudentService } from 'src/app/services/student.service';
import { debounce } from 'lodash';
import { Wilson } from 'src/def/Wilson';
import GetStudentsResponse = Wilson.GetStudentsResponse;
import Student = Wilson.Student;

@Component({
  selector: 'app-search-students',
  templateUrl: './search-students.component.html',
})
export class SearchStudentsComponent {
  @Input() existingStudentIds: string[];
  @Output() selectStudent = new EventEmitter<Student>();
  @Output() cancelAddExisting = new EventEmitter();

  suggestedStudents: Student[] = [];
  studentEmptyMessage = '';

  debouncedStudentSearch = debounce(async (event: FillSuggestionsEvent) => {
    const response = await this.studentService
      .getStudentsByName(event.query)
      .pipe(
        tap((response: GetStudentsResponse) => {
          this.suggestedStudents = response.students.filter(
            (student: Student) => !this.existingStudentIds.includes(student.id)
          );
          if (!this.suggestedStudents.length) {
            this.studentEmptyMessage = 'No results found';
          }
        })
      );
    await lastValueFrom(response);
  }, 1000);

  constructor(private studentService: StudentService) {}

  searchStudents(event: FillSuggestionsEvent): void {
    this.studentEmptyMessage = 'Searching...';

    if (event?.query?.trim().length > 2) {
      this.debouncedStudentSearch(event);
    } else {
      this.suggestedStudents = [];
      this.studentEmptyMessage = 'Keep typing...';
    }
  }

  onSelectStudent(student: Student): void {
    this.selectStudent.emit(student);
  }

  onCancel(): void {
    this.cancelAddExisting.emit();
  }
}

interface FillSuggestionsEvent {
  query: string;
  originalEvent: InputEvent;
}
