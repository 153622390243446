import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { catchError, Observable, of, take, tap } from 'rxjs';
import { InitialLoadService } from 'src/app/services/initial-load.service';
import { TestService } from 'src/app/services/test.service';
import { Wilson } from 'src/def/Wilson';
import GetUnitTestResponse = Wilson.GetUnitTestResponse;
import UnitTestCategory = Wilson.UnitTestCategory;
import GenerateUnitTestResponse = Wilson.GenerateUnitTestResponse;
import EditUnitTestResponse = Wilson.EditUnitTestResponse;

@Component({
  templateUrl: './test.component.html',
  selector: 'app-test',
  styleUrls: ['./test.component.scss'],
})
export class TestComponent implements OnInit, OnChanges {
  @Input() unitTest: GetUnitTestResponse;
  programLevel: string;
  testTitle: string;
  isReview = false;
  isRetest = false;

  constructor(
    private route: ActivatedRoute,
    private initialLoadService: InitialLoadService,
    private testService: TestService,
    private messageService: MessageService,
    private router: Router,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.setIsReview().subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['unitTest']?.currentValue) {
      this.setProgramLevel();
      this.setTestTitle();
    }
  }

  submitTest(): void {
    const studentId = this.route.snapshot.paramMap.get('studentId');
    const classId = this.route.snapshot.paramMap.get('classId');
    const testResponseId = this.route.snapshot.paramMap.get('testResponseId');

    const submitMethod =
      testResponseId && !this.isRetest
        ? this.submitEdit(studentId, classId, testResponseId)
        : this.submitNewTest(studentId, classId);

    submitMethod
      .pipe(
        tap(() => {
          this.messageService.add({
            summary: 'Success',
            detail: `The unit test response has been saved successfully`,
            severity: 'success',
          });
          this.returnToClass();
        }),
        catchError(() => {
          this.messageService.add({
            summary: 'Error',
            detail: 'Something went wrong. Please try again later',
            severity: 'error',
          });
          return of(null);
        })
      )
      .subscribe();
  }

  startRetest(): void {
    // Increment attemptNumber + reset other fields
    this.unitTest.testResponse.totalAttempts++;
    this.unitTest.testResponse.attemptNumber =
      this.unitTest.testResponse.totalAttempts;
    this.unitTest.testResponse.testDate =
      this.initialLoadService.initialLoad.currentDate;
    this.unitTest.testResponse.teacherNotes = '';
    this.unitTest.testCategories.forEach((category) => {
      category.questions.forEach((question) => {
        question.correctResponse = true;
      });
    });
    this.isRetest = true;
  }

  startEdit(): void {
    this.isRetest = false;
    this.isReview = false;
  }

  returnToClass(): void {
    void this.router.navigate([
      '/utt',
      'class',
      this.route.snapshot.paramMap.get('classId'),
    ]);
  }

  cancelAttempt(): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to cancel this attempt?',
      header: 'Cancel Attempt',
      defaultFocus: 'close',
      acceptButtonStyleClass: 'p-button-success',
      rejectButtonStyleClass: 'p-button-danger',
      accept: () => this.returnToClass(),
    });
  }

  getCategoryName(_: number, category: UnitTestCategory): string {
    return category.name;
  }

  getQuestionId(_: number, question: string): string {
    return question;
  }

  private setIsReview(): Observable<Data> {
    return this.route.data.pipe(
      take(1),
      tap((data) => (this.isReview = data.isReview))
    );
  }

  private submitEdit(
    studentId: string,
    classId: string,
    testResponseId: string
  ): Observable<void> {
    const editUnitTestResponse = {
      testResponseId,
      studentId,
      classId,
      testCategories: this.unitTest.testCategories,
      testResponse: this.unitTest.testResponse,
    } as EditUnitTestResponse;

    return this.testService.editTestResponse(editUnitTestResponse);
  }

  private submitNewTest(studentId: string, classId: string): Observable<void> {
    const generateUnitTestResponse = {
      studentId,
      classId,
      testCategories: this.unitTest.testCategories,
      testResponse: this.unitTest.testResponse,
    } as GenerateUnitTestResponse;

    return this.testService.generateTestResponse(generateUnitTestResponse);
  }

  private setProgramLevel(): void {
    this.programLevel = this.initialLoadService.initialLoad.funPrograms.find(
      (programDef) =>
        programDef.numericId === this.unitTest.testResponse.programId
    ).initials;
  }

  private setTestTitle(): void {
    this.testTitle = this.initialLoadService.initialLoad.testDefinitions.find(
      (def) => def.id === this.unitTest.testResponse.testDefinitionId
    ).shortName;
  }
}
