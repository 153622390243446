import { Component, OnInit } from '@angular/core';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { Store } from 'devextreme/data';
import mixpanel from 'mixpanel-browser';
import { InitialLoadService } from 'src/app/services/initial-load.service';
import { Wilson } from 'src/def/Wilson';
import BenchmarkScoresViewModel = Wilson.BenchmarkScoresViewModel;
import { DevExtremeHelper } from 'src/app/helpers/devExtremeHelper';

@Component({
  templateUrl: './my-students.component.html',
})
export class MyStudentsComponent implements OnInit {
  store: Store;
  benchmarkScores: BenchmarkScoresViewModel;
  studentCount: number;

  unitsFilter: { name: string; value: number }[];

  constructor(private initialLoadService: InitialLoadService) {}

  ngOnInit(): void {
    this.setupStore();
    mixpanel.track('Go to My Students');
    this.benchmarkScores = this.initialLoadService.initialLoad.benchmarkScores;
    this.setupUnitsFilters();
  }

  /* istanbul ignore next */
  setupStore(): void {
    this.store = createStore({
      key: 'classId',
      loadUrl: '/students/GetMyStudentsDataTable',
      onLoaded: (students) => {
        this.studentCount = students?.length || 0;
      },
      onBeforeSend: (e, ajaxOptions: any) =>
        DevExtremeHelper.onBeforeSend(e, ajaxOptions),
    });
  }
  setupUnitsFilters(): void {
    // NOTE: It's ok to have "too many" units. DX only shows values that actually exist in the table
    this.unitsFilter = this.initialLoadService.initialLoad.testDefinitions.map(
      (td) => ({
        name: td.name,
        value: td.id,
      })
    );
  }
}
