import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs';
import { TestService } from 'src/app/services/test.service';
import { Wilson } from 'src/def/Wilson';
import GetUnitTestResponse = Wilson.GetUnitTestResponse;

@Component({
  templateUrl: './new-test.component.html',
})
export class NewTestComponent implements OnInit {
  unitTestResponse: GetUnitTestResponse;

  constructor(
    private route: ActivatedRoute,
    private testService: TestService
  ) {}

  ngOnInit(): void {
    this.testService
      .getNewTestResponse(
        this.route.snapshot.paramMap.get('studentId'),
        this.route.snapshot.paramMap.get('testDefinitionId')
      )
      .pipe(
        tap((response) => {
          this.unitTestResponse = response;
        })
      )
      .subscribe();
  }
}
