import { Injectable } from '@angular/core';
import { ApiService } from '@wilson/wilsonng';
import { Observable } from 'rxjs';
import { Wilson } from 'src/def/Wilson';
import EditClass = Wilson.EditClass;
import HubProgramUnits = Wilson.HubProgramUnits;
import BreadcrumbResponse = Wilson.BreadcrumbResponse;
import GetClassOverviewResponse = Wilson.GetClassOverviewResponse;
import GetClassOverviewCsvExport = Wilson.GetClassOverviewCsvExport;
import BaseResult = Wilson.BaseResult;

@Injectable({
  providedIn: 'root',
})
export class ClassOverviewService {
  private _programUnits: HubProgramUnits[];

  constructor(private apiService: ApiService) {
    /* istanbul ignore next */
    if (window['parent']['Cypress']) window['ApiService'] = this;
  }

  getClassOverview(classId: string): Observable<GetClassOverviewResponse> {
    return this.apiService.get<GetClassOverviewResponse>(
      `classOverview/GetClassOverview/${classId}`
    );
  }

  getClassName(classId: string): Observable<BreadcrumbResponse> {
    return this.apiService.get<BreadcrumbResponse>(
      `classOverview/GetClassName/${classId}`
    );
  }

  getClassOverviewCsvExport(
    classId: string
  ): Observable<GetClassOverviewCsvExport[]> {
    return this.apiService.get<GetClassOverviewCsvExport[]>(
      `classOverview/GetClassOverviewCsvExport/${classId}`
    );
  }

  deleteClass(classId: string): Observable<unknown> {
    return this.apiService.post(`classOverview/RemoveClass/${classId}`);
  }

  updateClass(classId: string, data: EditClass): Observable<BaseResult> {
    return this.apiService.put(`classOverview/EditClass/${classId}`, data);
  }
}
