import { Injectable } from '@angular/core';
import { ApiService } from '@wilson/wilsonng';
import { Observable } from 'rxjs';
import { Wilson } from 'src/def/Wilson';
import GetMyClassesResponse = Wilson.GetMyClassesResponse;

@Injectable({
  providedIn: 'root',
})
export class MyClassesService {
  constructor(private apiService: ApiService) {}

  getMyClassesResponse(): Observable<GetMyClassesResponse> {
    return this.apiService.get<GetMyClassesResponse>(`myClasses/GetMyClasses/`);
  }
}
